import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { States } from '@@services/services';
import { RealtimeManagerContext, connectRealtimeHost, realtimeHost, IRealtimeManager } from '@@services/realtimeservice';

export interface ICommunityRealtimeProps {
    community?: States.ICommunityState;
    user?: States.IAppUserState;
    children?: React.ReactNode;
}

export interface ICommunityRealtimeState {
    manager: Promise<IRealtimeManager>;
}

@connectwith((state: States.IAppState) => {
    return {
        community: state.community,
        user: state.user
    };
})
export class CommunityRealtime extends React.Component<ICommunityRealtimeProps, ICommunityRealtimeState> {
    constructor(props : ICommunityRealtimeProps) {
        super(props);

        this.state = {
            manager: this.getCurrentManager(props)
        };
    }

    componentDidUpdate(prevprops: ICommunityRealtimeProps) {
        const eventChanged = prevprops.community.communityid !== this.props.community.communityid;
        const notInitialized = !this.state.manager && this.props.community.detail;

        if (eventChanged || notInitialized) {
            this.setState({
                manager: this.getCurrentManager(this.props)
            });
        }

        if (this.props.user.currentUser?.detail !== prevprops.user.currentUser?.detail) {
            this.state.manager.then((mgr) => mgr.registerUser(this.props.community.communityid));
        }
    }

    getCurrentManager(props: ICommunityRealtimeProps) {
        if (__SERVERSIDE__) {
            return null;
        }

        if (props.community && props.community.detail) {
            let host = props.community.detail.configuration?.realtime?.default;
            if (!host) {
                host = realtimeHost();
            }
            const manager = connectRealtimeHost(host);

            if (InWinkPreview || props.user.currentUser?.detail) {
                manager.then((mgr) => {
                    mgr.init();
                    mgr.registerUser(props.community.communityid);
                });
            }
            return manager;
        }
    }

    render() {
        return <RealtimeManagerContext.Provider value={this.state.manager}>
            {this.props.children}
        </RealtimeManagerContext.Provider>;
    }
}
