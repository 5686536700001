import * as React from 'react';
import { Entities } from '@inwink/entities/entities';
import { VisualConfiguration } from '@inwink/visualconfiguration';
import type { States } from '@@services/services';
import { communityModule } from '@@routes/appmodules';
import { wrapReduxStore, IInwinkStore} from '@@store/index';
import { parse } from '@inwink/utils/querystring';
import { communityMailTracking } from '@@api/front/mailtracking';
import { CookieTracking } from '@@components/cookiebar';
import { isFromServer } from '@@pages/helpers';
import { GuidPattern } from '@@services/index';

export interface ICommunityDataProps {
    community: States.ICommunityState;
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    location: States.ILocation;
    match: States.ILocationMatch;
    store?: IInwinkStore;
    i18n?: States.i18nState;
    children?: React.ReactNode;
}

export interface ICommunityDataState {
}

class CommunityShellDataComponent extends React.Component<ICommunityDataProps, ICommunityDataState> {
    container = React.createRef<HTMLDivElement>();

    constructor(props: ICommunityDataProps) {
        super(props);

        const isFromSSR = isFromServer();
        if (!__SERVERSIDE__ && !isFromSSR && !props.community?.isReady) {
            if (!isFromSSR) {
                this.trackMail();
            }
            communityModule().then((mod) => {
                mod.bootstrapCommunityActions.communityBootstrap(
                    __SERVERSIDE__,
                    props.location,
                    props.match,
                    props.community.communityid,
                    props.community.tinyUrl
                )(this.props.store.dispatch, this.props.store.getState).then(() => {
                    console.log("COMMUNITY YOUPI");
                }, (err) => {
                    console.error("error loading community", err);
                });
            });
        } else {
            this.trackMail();
        }

        this.state = {
        };
    }

    trackMail() {
        if (this.props.community?.requestManagers?.apiFront
            && this.props.community?.detail?.id
            && this.props.location.search && this.props.location.search.indexOf('iw_mailid=') > -1) {
            const args = parse(this.props.location.search);
            const iwmailid = args?.iw_mailid;
            if (iwmailid && GuidPattern.test(iwmailid)) {
                // tracking d'email
                communityMailTracking(
                    this.props.community?.requestManagers?.apiFront,
                    this.props.community?.detail?.id,
                    iwmailid
                );
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            import("../../../services/activityservice").then((mod) => {
                mod.userActivityActions.trackCurrentPage();
            });
        }, 1000);
    }

    render() {
        let content = null;
        if (this.props.community?.isReady) {
            content = this.props.children;
        }
        // return <div className="app-shell-data">
        //     <VisualConfiguration
        //         visualConfiguration={this.props.visualConfiguration}
        //         prefix={".community-" + (this.props.community?.communityid || "") + " "}
        //     />
        //     {content}
        // </div>;

        
        const cookiebarCfg = this.props.community?.detail?.configuration?.companion?.cookiesWarning;
        const trackingCfg = this.props.community?.detail?.configuration?.companion?.tracking;

        return (
            <CookieTracking
                i18n={this.props.i18n}
                eventId={this.props.community?.detail?.id}
                websiteId={null}
                cookieid={this.props.community?.detail?.id}
                config={cookiebarCfg}
                tracking={trackingCfg}
            >
                {(cookiebar, contentClassName) => {         
                    const classNames = `app-shell community-${this.props.community?.communityid} ${contentClassName || ''}`;   
                    
                    if (this.container?.current) {
                        if (classNames !== this.container.current.className) {
                            this.container.current.className = classNames;
                        }
                    }

                    return (<div ref={this.container} className={classNames}>
                        <div className={"app-shell-data"}>
                            <VisualConfiguration
                                visualConfiguration={this.props.visualConfiguration}
                                prefix={".community-" + (this.props.community?.communityid || "") + " "}
                            />
                            {content}
                        </div>
                        {cookiebar}
                    </div>);
                }}
            </CookieTracking>
        );
    }
}

export const CommunityShellData = wrapReduxStore(CommunityShellDataComponent);
