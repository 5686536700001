import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type {
    ICommunityForum,
    ICommunityForumTag
} from '@@community/data';
import type { IFrontDatasourceV3 } from '../../../api/front/datasource';
import { defaultPostHeaderForJsonData } from '@inwink/apibase';

function forumDatasourceV3(requestManager: ICommunityRequestManager) : Promise<IFrontDatasourceV3<ICommunityForum>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = `community/${requestManager.communityId}/forum`;
        return mod.getFrontDataSource<ICommunityForum>(
            requestManager, url
        );
    });
}

function forumTagDatasourceV3(requestManager: ICommunityRequestManager
    , forumId: string) : Promise<IFrontDatasourceV3<ICommunityForumTag>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = `community/${requestManager.communityId}/forum/${forumId}/tag`;
        return mod.getFrontDataSource<ICommunityForumTag>(
            requestManager, url
        );
    });
}

function forumCategoryDatasourceV3(requestManager: ICommunityRequestManager
    , forumId: string) : Promise<IFrontDatasourceV3<ICommunityForumTag>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = `community/${requestManager.communityId}/forum/${forumId}/category`;
        return mod.getFrontDataSource<ICommunityForumTag>(
            requestManager, url
        );
    });
}

export interface IForumQueryOptions extends IInwinkEntityV3QueryOptions {
    groupId?: string;
    addGroupForums?: boolean;
    adminStatus?: string[];
}

export function forumsQuery(
    requestManager: ICommunityRequestManager,
    options?: IForumQueryOptions
) {
    const targetOptions = options || {};
    return forumDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function forumsCount(
    requestManager: ICommunityRequestManager,
    options?: IForumQueryOptions
) {
    const targetOptions = options || {};
    return forumDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}

export function forumTagsQuery(requestManager: ICommunityRequestManager,
    forumId: string, options?: IInwinkEntityV3QueryOptions) {
    const targetOptions = options || {};
    return forumTagDatasourceV3(requestManager, forumId).then((ds) => ds.query(targetOptions));
}

export function forumCategoriesQuery(requestManager: ICommunityRequestManager,
    forumId: string, options?: IInwinkEntityV3QueryOptions) {
    const targetOptions = options || {};
    return forumCategoryDatasourceV3(requestManager, forumId).then((ds) => ds.query(targetOptions));
}

export function forumDetail(
    requestManager: ICommunityRequestManager,
    forumId: string
) {
    const url = `community/${requestManager.communityId}/forum/${forumId}/detail`;
    return requestManager.postJson(url, JSON.stringify({
        selects: {
            $all: true,
            topics: {
                id: true,
                title: true
            }
        }
    }), defaultPostHeaderForJsonData);
}

export function forumNotificationOptOut(
    addForumOptOut: string[],
    removeForumOptOut: string[],
    requestManager: ICommunityRequestManager,
) {
    const url = `community/${requestManager.communityId}/forum/optout`;
    const payload = JSON.stringify({
        contentIdOptOuts: addForumOptOut,
        contentIdRemoveOptOuts: removeForumOptOut
    });
    return requestManager.postJson(url, payload, defaultPostHeaderForJsonData);
}

