/* eslint-disable react/prefer-stateless-function */
import * as React from "react";
import { bindActionCreators } from "redux";
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { networkingActions } from '@@community/features/networking/actions';
import type { IAppHeaderCommunityProps } from "./index";

import "./chat.less";
import { AsyncButton } from "@inwink/buttons";

export interface IAppHeaderChatProps extends IAppHeaderCommunityProps {
    networkingActions?: typeof networkingActions;
    i18nHelper?: Entities.i18nHelper;
}
export interface IAppHeaderChatState {
    unread: number;
}
@connectwith(null, (dispatch) => {
    return {
        networkingActions: bindActionCreators(networkingActions, dispatch)
    };
})
@withI18nHelper()
export class AppHeaderChat extends React.Component<IAppHeaderChatProps, IAppHeaderChatState> {
    registrationKey: string;

    componentDidMount() {
        this.registrationKey = `inwink.${this.props.community.communityid}.discussion`;
        window.addEventListener(this.registrationKey, this.discussionUpdate);
        this.getMessagesCount();
    }

    componentWillUnmount() {
        window.removeEventListener(this.registrationKey, this.discussionUpdate);
    }

    discussionUpdate = (arg) => {
        if (arg?.detail?.totalUnreadMessages !== undefined) {
            this.setState({ unread: arg.detail.totalUnreadMessages });
        }
    };

    openDiscussions = (arg?: React.MouseEvent<any>) => {
        arg.preventDefault();
        return (this.props.networkingActions.showDiscussions(arg.currentTarget, this.props.i18nHelper) as any);
    };

    getMessagesCount = () => {
        import("@@community/api/notifications").then((mod) => {
            mod.notificationsCount(this.props.community.requestManagers.apiFront, {
                filters: {
                    notificationType: {
                        $equal: "NewDiscussionThreadMessage"
                    },
                    dismissed: {
                        $notEqual: true
                    }
                }
            }).then((res) => {
                this.setState({ unread: res });
            });
        });
    };

    render() {
        if (!this.props.user?.currentUser?.member) {
            return null;
        }

        return <div className="appheader-action-button btnchat">
            <AsyncButton className="button iconbtn rel" onClick={this.openDiscussions}>
                <i className="inwink-chat" />
                {this.state?.unread ? <span className="badge">{this.state.unread}</span> : null}
            </AsyncButton>
        </div>;
    }
}
