import * as React from 'react';
import { TransitionGroup } from "react-transition-group";
import { withI18nHelper } from "@inwink/i18n/reactcontext";
import type { Entities } from "@inwink/entities/entities";
import { SimpleFadeTransition } from "@@components/transitions";
import { FullNameBubble } from "@@components/personbubble/personbubble";
import { userMessageModule } from "@@routes/appmodules";
import { wrapReduxStore, IInwinkStore } from "@@store/index";
import { SideModal } from '@inwink/modals/modal';
import type { IAppHeaderCommunityProps } from "./index";

const ProfilePanel = React.lazy(() => import("./profile"));

import './avatar.less';

export interface IAppHeaderAvatarProps extends IAppHeaderCommunityProps {
    i18nHelper?: Entities.i18nHelper;
    history?: any;
    store?: IInwinkStore;
}

interface IAppHeaderAvatarState {
    showSidebar: boolean;
    initialized: boolean;
}

@withI18nHelper()
class AppHeaderAvatarComponent extends React.Component<IAppHeaderAvatarProps, IAppHeaderAvatarState> {
    constructor(props) {
        super(props);
        this.state = {
            showSidebar: false,
            initialized: false
        };
    }

    componentDidMount(): void {
        if (!this.state.initialized) {
            this.setState({
                initialized: true
            });
        }
    }

    onClick = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const isUserConnected = !!this.props.user?.currentUser;
        if (isUserConnected) {
            this.setState({ showSidebar: true });
        } else {
            userMessageModule().then((mod) => {
                const store = this.props.store;
                mod.loginActions.showLogin()(store.dispatch, store.getState);
            });
        }
    };

    hide = () => {
        this.setState({ showSidebar: false });
    };

    sideBar = (props) => {
        return <React.Suspense fallback={<div />}>
            <ProfilePanel {...props} onHide={this.hide} />
        </React.Suspense>;
    };

    render() {
        const { user, i18nHelper } = this.props;
        const isUserConnected = !!user?.currentUser;
        const userPicture = user?.currentUser?.member?.photo?.url;

        let title = '';
        let avatarNode;
        if (isUserConnected) {
            avatarNode = userPicture ? <FullNameBubble fullname="" picture={userPicture} /> : <i className="inwink-account" />;
            title = i18nHelper.translate('networking.menu.profile.infos');
        } else {
            avatarNode = <i className="inwink-login" />;
            title = i18nHelper.translate('menu.anonymous');
        }

        // const onClickAvatar = (arg: React.MouseEvent<any>, urlservice?: States.IAppUrlContext) => {
        //     const target = arg.currentTarget;

        //     if (isUserConnected) {
        //         import("@@community/features/user/useractions").then((mod) => {
        //             mod.userActions.showProfile(this.props.i18nHelper, target)(
        //                 this.props.store.dispatch, this.props.store.getState
        //             );
        //         });
        //     } else {
        //         userMessageModule().then((mod) => {
        //             const store = this.props.store;
        //             mod.loginActions.showLogin()(store.dispatch, store.getState);
        //         });
        //     }
        // };

        return (
            <TransitionGroup component="div" className="avatar-content avatarcommunity-content">
                <SimpleFadeTransition key="avatar">
                    <button
                        type="button"
                        onClick={this.onClick}
                        title={title}
                    >
                        { avatarNode }
                    </button>
                    
                </SimpleFadeTransition>
                {this.state.initialized ? <SideModal
                    className={"community-headerprofilepanel community-" + this.props.community.communityid}
                    show={this.state.showSidebar}
                    onhide={this.hide}
                >
                    <this.sideBar {...this.props} />
                </SideModal> : null}
            </TransitionGroup>
        );
    }
}

export const AppHeaderAvatar = wrapReduxStore(AppHeaderAvatarComponent);