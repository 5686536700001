import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type {
    ICommunityForumSubject, IMember,
} from '@@community/data';
import { IFrontDatasourceV3, IInwinkEntityQueryResult } from '../../../api/front/datasource';
import { defaultPostHeaderForJsonData } from '@@api/index';

function forumSubjectDatasourceV3(requestManager: ICommunityRequestManager,
    forumId: string): Promise<IFrontDatasourceV3<ICommunityForumSubject>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = `community/${requestManager.communityId}/forum/${forumId}/subject`;
        return mod.getFrontDataSource<ICommunityForumSubject>(
            requestManager, url
        );
    });
}

export function forumSubjectsCount(requestManager: ICommunityRequestManager,
    forumId: string, options?: IInwinkEntityV3QueryOptions) {
    const targetOptions = options || {};
    return forumSubjectDatasourceV3(requestManager, forumId).then((ds) => ds.count(targetOptions));
}

export function forumSubjectsQuery(requestManager: ICommunityRequestManager,
    forumId: string, options?: IInwinkEntityV3QueryOptions) {
    const targetOptions = options || {};
    return forumSubjectDatasourceV3(
        requestManager,
        forumId
    ).then((ds) => ds.query(targetOptions).then((res) => {
        if (res.data?.length) {
            res.data.forEach((subject) => {
                if (subject.info) {
                    // hack pourri pour l'affichage sinon dans le code des templates il considère comme null et retourne ''
                    subject.info.replies += '';
                    subject.info.likes += '';
                }
            });
        }

        return res;
    }));
}

export function forumSubjectSearchCount(
    requestManager: ICommunityRequestManager,
    forumId: string,
    searchtext?: string,
    options?: IInwinkEntityV3QueryOptions
) {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/searchquery/count`;
    return requestManager.postJson<number>(url, JSON.stringify({
        search: searchtext,
        selects: options?.selects,
        orders: options?.orders,
        page: options?.page,
        filters: options?.filters
    }), defaultPostHeaderForJsonData);
}

export function forumSubjectSearchQuery(
    requestManager: ICommunityRequestManager,
    forumId: string,
    searchtext?: string,
    options?: IInwinkEntityV3QueryOptions
): Promise<IInwinkEntityQueryResult<unknown>> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/searchquery`;
    return requestManager.postJson(url, JSON.stringify({
        search: searchtext,
        selects: options?.selects,
        orders: options?.orders,
        page: options?.page,
        filters: options?.filters
    }), defaultPostHeaderForJsonData);
}

export function createForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    forumSubject: Partial<ICommunityForumSubject>,
    // selects: any = null
) {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/create`;

    return requestManager.postJson(url, JSON.stringify({
        entity: forumSubject,
        selects: {
            $all: true,
            categorySubjects: { $all: true },
            forumTags: { $all: true }
        }
        // selects: selects
    }), defaultPostHeaderForJsonData);
}

export function updateForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    forumSubject: Partial<ICommunityForumSubject>,
    forumSubjectId: string
) {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${forumSubjectId}`;
    return requestManager.postJson(url, JSON.stringify({
        entity: forumSubject,
        selects: {
            $all: true,
            categorySubjects: { $all: true },
            forumTags: { $all: true }
        }
    }), defaultPostHeaderForJsonData);
}

export function deleteForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    forumSubjectId: Partial<ICommunityForumSubject>
) {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${forumSubjectId}/delete`;

    return requestManager.postJson(url);
}

export function followForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string
): Promise<{followed: boolean}> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/follow`;
    return requestManager.postJson(url);
}

export function unfollowForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string
): Promise<{followed: boolean}> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/unfollow`;

    return requestManager.postJson(url);
}

export function getFollowForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string
): Promise<{ followed: boolean, createdAt: string }> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/followed`;
    return requestManager.getJson(url);
}

export function PinForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string,
    member: IMember
): Promise<{ followed: boolean, createdAt: string }> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/pin`;
    return requestManager.postJson(url, JSON.stringify({
        member: member
    }));
}

export function UnpinForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string,
    member: IMember
): Promise<{ followed: boolean, createdAt: string }> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/unpin`;
    return requestManager.postJson(url, JSON.stringify({
        member: member
    }));
}

export function getLikeForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string
): Promise<{ liked: boolean, createdAt: string }> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/like`;
    return requestManager.getJson(url);
}

export function likeForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string
): Promise<{likes: number}> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/like`;
    return requestManager.postJson(url);
}

export function unlikeForumSubject(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string
): Promise<{likes: number}> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/unlike`;
    return requestManager.postJson(url);
}

export function forumSubjectsDetail(
    requestManager: ICommunityRequestManager,
    forumId: string,
    subjectId: string
): Promise<IInwinkEntityQueryResult<any>> {
    const url = `community/${requestManager.communityId}/forum/${forumId}/subject/${subjectId}/detail`;
    return requestManager.postJson(url, JSON.stringify({
        selects: {
            $all: true,
            forum: {
                $all: true,
                memberAccesses: {
                    id: true
                }
            },
            categorySubjects: { $all: true },
            forumTags: { $all: true },
            member: { $all: true },
            forumSubjectAssets: {
                $all: true
            }
        }
    }), defaultPostHeaderForJsonData);
}