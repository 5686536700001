import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { ICommunityCompany } from '@@community/data';
import type { IFrontDatasourceV3 } from '../../../api/front/datasource';
import { defaultPostHeaderForJsonData } from '@@api/index';

export function companyDatasourceV3(requestManager: ICommunityRequestManager) : Promise<IFrontDatasourceV3<ICommunityCompany>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/company";
        return mod.getFrontDataSource<ICommunityCompany>(
            requestManager, url
        );
    });
}

export function companiesQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return companyDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function EditCompany(
    requestManager: ICommunityRequestManager,
    companyId: string,
    patch: Partial<ICommunityCompany>
) {
    const url = `community/${requestManager.communityId}/company/${companyId}`;
    return requestManager.postJson(
        url,
        JSON.stringify({
            entity: patch
        }),
        defaultPostHeaderForJsonData
    );
}

export function companiesCount(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return companyDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}

export function bookmarked(
    requestManager: ICommunityRequestManager,
    entityId: string
) {
    const url = "community/" + requestManager.communityId + "/company/" + entityId + "/bookmark";

    return requestManager.getJson(url);
}

export function bookmark(
    requestManager: ICommunityRequestManager,
    entityId: string
) : Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/company/" + entityId + "/bookmark";

    return requestManager.postJson(url);
}

export function removeBookmark(
    requestManager: ICommunityRequestManager,
    entityId: string
) : Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/company/" + entityId + "/removebookmark";

    return requestManager.postJson(url);
}

