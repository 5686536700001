import { defaultPostHeaderForJsonData } from '@@api/front';
import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { ICommunityContent, ICommunityContentType, ILink } from '@@community/data';
import type { IFrontDatasourceV3 } from '@@api/front/datasource';

export function contentsDatasourceV3(requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType) : Promise<IFrontDatasourceV3<ICommunityContent>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/" + contentType;
        return mod.getFrontDataSource<ICommunityContent>(
            requestManager, url
        );
    });
}

export function contentInfo(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string,
    options?: IInwinkEntityV3QueryOptions
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/info";

    return requestManager.postJson<{
        data: ICommunityContent[];
    }>(url, options && JSON.stringify(options), defaultPostHeaderForJsonData);
}

export function contentDetail(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string,
    options?: IInwinkEntityV3QueryOptions
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/detail";

    return requestManager.postJson<{
        data: ICommunityContent[];
    }>(url, options && JSON.stringify(options), defaultPostHeaderForJsonData);
}

export interface IContentQueryOptions extends IInwinkEntityV3QueryOptions {
    bookmarked?: boolean;
    recommanded?: boolean;
    groupId?: string;
    adminStatus?: string[];
}

export function contentQuery(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    options?: IContentQueryOptions
) {
    const targetOptions = options || {};
    return contentsDatasourceV3(requestManager, contentType).then((ds) => ds.query(targetOptions));
}

export function contentCount(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    options?: IContentQueryOptions
) {
    const targetOptions = options || {};
    return contentsDatasourceV3(requestManager, contentType).then((ds) => ds.count(targetOptions));
}

export function latestComments(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/lastcomments";

    return requestManager.getJson<any>(url);
}

export interface ICommentsQuery {
    parentId?: string;
    continuationToken?: string;
}
export function getComments(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string,
    query?: ICommentsQuery
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/comments";

    return requestManager.postJson<{
        data: any[];
        continuationToken: string;
    }>(url, query ? JSON.stringify(query) : null, defaultPostHeaderForJsonData);
}

export function removeComment(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string,
    commentId: string
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/comments/remove/" + commentId;

    return requestManager.postJson<any>(url);
}

export function updateComment(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string,
    commentId: string,
    text: string,
    links: ILink[]
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/comments/update/" + commentId;

    return requestManager.postJson<any>(url, JSON.stringify({ text, links }), defaultPostHeaderForJsonData);
}

export function comment(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string,
    text: string,
    links: ILink[],
    replyTo?: string
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/comment";

    return requestManager.postJson(url, JSON.stringify({
        text,
        links,
        replyTo
    }), defaultPostHeaderForJsonData);
}

export function liked(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/like";

    return requestManager.getJson(url);
}

export function like(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
): Promise<{likes: number}> {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/like";

    return requestManager.postJson(url);
}

export function trackDownload(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/trackdownload";

    return requestManager.postJson(url);
}

export function trackWatch(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/trackwatch";

    return requestManager.postJson(url);
}

export function unlike(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
): Promise<{likes: number}> {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/unlike";

    return requestManager.postJson(url);
}

export function bookmarked(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/bookmark";

    return requestManager.getJson(url);
}

export function bookmark(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
): Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/bookmark";

    return requestManager.postJson(url);
}

export function removeBookmark(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string
): Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/removebookmark";

    return requestManager.postJson(url);
}

export function update(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentId: string,
    contentPatch: any,
    selects: any = null
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/" + contentId + "/update";

    return requestManager.postJson(url, JSON.stringify({
        entity: contentPatch,
        selects: selects
    }), defaultPostHeaderForJsonData);
}

export function create(
    requestManager: ICommunityRequestManager,
    contentType: ICommunityContentType,
    contentPatch: any,
    selects: any = null
) {
    const url = "community/" + requestManager.communityId + "/" + contentType + "/create";

    return requestManager.postJson(url, JSON.stringify({
        entity: contentPatch,
        selects: selects
    }), defaultPostHeaderForJsonData);
}

export function registerIWEvent(
    requestManager: ICommunityRequestManager,
    eventId: string,
    tenantId: string
) {
    const url = "community/" + requestManager.communityId + "/" + tenantId + "/" + "events" + "/" + eventId + "/registermember";

    return requestManager.postJson(url);
}

export function unregisterIWEvent(
    requestManager: ICommunityRequestManager,
    eventId: string,
    tenantId: string
) {
    const url = "community/" + requestManager.communityId + "/" + tenantId + "/" + "events" + "/" + eventId + "/unregistermember";

    return requestManager.postJson(url);
}
