/* eslint-disable react/prefer-stateless-function */
import * as React from "react";
import { bindActionCreators } from "redux";
import { States } from '@@services/services';
import { Entities } from '@inwink/entities/entities';
import { SideModal } from '@inwink/modals/modal';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { networkingActions } from '@@community/features/networking/actions';
import { metadataNotifsActions } from '@@services/appmetadataactions/notifs';
import type { IAppHeaderCommunityProps } from "./index";

import "./notifications.less";

export interface IAppHeaderNotificationsProps extends IAppHeaderCommunityProps {
    showNotificationCenter?: boolean;
    networkingActions?: typeof networkingActions;
    metadataNotifsActions?: typeof metadataNotifsActions;
    i18nHelper?: Entities.i18nHelper;
}
export interface IAppHeaderNotificationsState {
    unread: number;
}

const NotifCenter = React.lazy(() => import("../notifications"));

@connectwith((state: States.IAppState) => {
    return {
        showNotificationCenter: state.appMetaData.showNotificationCenter
    };
}, (dispatch) => {
    return {
        networkingActions: bindActionCreators(networkingActions, dispatch),
        metadataNotifsActions: bindActionCreators(metadataNotifsActions, dispatch)
    };
})
@withI18nHelper()
export class AppHeaderNotifications extends React.Component<IAppHeaderNotificationsProps, IAppHeaderNotificationsState> {
    registrationKey: string;

    componentDidMount() {
        this.registrationKey = `inwink.${this.props.community.communityid}.notification`;
        window.addEventListener(this.registrationKey, this.notificationUpdate);
        import("@@community/api/notifications").then((mod) => {
            mod.notificationsCount(this.props.community.requestManagers.apiFront, {
                filters: {
                    relatedToKind: {
                        $notEqual: "DiscussionThread"
                    },
                    dismissed: {
                        $notEqual: true
                    }
                }
            }).then((res) => {
                this.setState({ unread: res });
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener(this.registrationKey, this.notificationUpdate);
    }

    notificationUpdate = (arg) => {
        const detail = arg.detail;
        const unread = detail.unreadNotification;
        if (detail) {
            if (unread !== undefined) {
                this.setState({ unread: unread });
            }
        }
    };

    openNotifications = (arg?: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.props.metadataNotifsActions.notifCenter(true);
    };

    hide = () => {
        this.props.metadataNotifsActions.notifCenter(false);
    };

    empty = () => null;

    render() {
        if (!this.props.user?.currentUser?.member) {
            return null;
        }

        return <div className="appheader-action-button btnnotifs">
            <button type="button" className="button iconbtn" onClick={this.openNotifications}>
                <i className="inwink-bell" />
                {this.state?.unread ? <span className="badge">{this.state.unread}</span> : null}
            </button>
            <SideModal
                className="community-headernotificationspanel"
                show={this.props.showNotificationCenter}
                onhide={this.hide}
            >
                <NotifPanel community={this.props.community} />
            </SideModal>
        </div>;
    }
}

function NotifPanel(props: { community: States.ICommunityState, onCompleted?: (res?) => void }) {
    return <div className={"notificationspanel-content community-" + props.community.communityid}>
        <div className="notificationspanel-wrapper bloctheme">
            <React.Suspense fallback={null}>
                <NotifCenter onCompleted={props.onCompleted} />
            </React.Suspense>
        </div>
    </div>;
}
