import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { IFrontDatasourceV3 } from '../../../api/front/datasource';
import { ICommunityCompanyOffering } from '../data';

export function companyofferingDatasourceV3(
    requestManager: ICommunityRequestManager) : Promise<IFrontDatasourceV3<ICommunityCompanyOffering>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/company/offering";
        return mod.getFrontDataSource<ICommunityCompanyOffering>(
            requestManager, url
        );
    });
}

export function companyofferingQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return companyofferingDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function companyofferingCount(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return companyofferingDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}

export function bookmarked(
    requestManager: ICommunityRequestManager,
    entityId: string
) {
    const url = "community/" + requestManager.communityId + "/company/offering/" + entityId + "/bookmark";

    return requestManager.getJson(url);
}

export function bookmark(
    requestManager: ICommunityRequestManager,
    entityId: string
) : Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/company/offering/" + entityId + "/bookmark";

    return requestManager.postJson(url);
}

export function removeBookmark(
    requestManager: ICommunityRequestManager,
    entityId: string
) : Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/company/offering/" + entityId + "/removebookmark";

    return requestManager.postJson(url);
}

