import * as React from 'react';
import { connect } from 'react-redux';
import { I18NResourcesContext } from '@inwink/i18n/resourcecontext';
import { States } from '../../../services/services';
import { getTrads } from '../../../data/templates';

export interface ICommunityTradsContextProps {
    community?: States.ICommunityState;
    children?: React.ReactNode;
}

class CommunityTradsContextComponent extends React.PureComponent<ICommunityTradsContextProps, any> {
    constructor(props: ICommunityTradsContextProps) {
        super(props);
        this.state = {
            trads: getTrads(this.props.community?.data)
        };
    }

    componentDidUpdate(prevprops: ICommunityTradsContextProps) {
        if (prevprops.community !== this.props.community) {
            const trads = getTrads(this.props.community?.data);
            if (trads !== this.state.trads) {
                this.setState({ trads: trads });
            }
        }
    }

    render() {
        return <I18NResourcesContext
            resources={this.state.trads && this.state.trads.labels}
        >
            {this.props.children}
        </I18NResourcesContext>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        community: state.community
    };
}

export const CommunityTradsContext: new (any) => React.Component<ICommunityTradsContextProps, any> = connect(
    mapStateToProps,
    null
)(CommunityTradsContextComponent as any) as any;
