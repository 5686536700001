import type { ICommunityContentType, IMember } from "./index";
import { getFiltersFromUrlParams as getFiltersFromUrlParamsBase } from '@@data/entityfilters';
import { Entities } from "@inwink/entities/entities";

export function getBaseFilters(member: IMember, contentType: ICommunityContentType) {
    const selects: any = {
        $all: true,
        info: {
            $all: true
        },
        member: {
            firstname: true,
            lastname: true,
            company: true,
            jobTitle: true
        },
        memberAccess: {
            $all: true
        },
        topics: {
            id: true,
            title: true,
            visualConfig: true
        },
        group: {
            id: true,
            title: true
        },
        company: {
            id: true,
            name: true,
            logo: true
        }
    };

    if (contentType === "IWEvent") {
        selects.vod = {
            status: true
        };
        selects.speakers = {
            $all: true
        };
        selects.moderators = {
            $all: true
        };
        // selects.webinarSpeakers = {
        //     memberId: true,
        //     member: {
        //         $all: true
        //     },
        // };
    }

    return selects;
}

export function getFiltersFromUrlParams(qargs: any, fieldtemplate: Entities.IFieldTemplate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchable: Entities.IFilterTemplate) {
    
    const filters = getFiltersFromUrlParamsBase(qargs, fieldtemplate, searchable);
    if (filters && Object.keys(filters).length > 0) {
        if (fieldtemplate) {
            fieldtemplate.template.fields.forEach((entityfield) => {
                const filterValue = filters[entityfield.key];
                if (filterValue) {
                    if (entityfield.type.toLowerCase() === "entity"
                        && filterValue
                        && typeof filterValue === "string") {
                        filters[entityfield.key] = filterValue.split(',');
                    }
                }
            });
        }
    }
    return filters;
}