import { defaultPostHeaderForJsonData } from '@@api/front';
import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { ICommunityTopic } from '@@community/data';
import type { IFrontDatasourceV3 } from '../../../api/front/datasource';

export function topicDatasourceV3(requestManager: ICommunityRequestManager) : Promise<IFrontDatasourceV3<ICommunityTopic>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/topic";
        return mod.getFrontDataSource<ICommunityTopic>(
            requestManager, url
        );
    });
}

export function topicQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return topicDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function topicCount(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return topicDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}

export function bookmarked(
    requestManager: ICommunityRequestManager,
    contentId: string
) {
    const url = "community/" + requestManager.communityId + "/topic/" + contentId + "/bookmark";

    return requestManager.getJson(url);
}

export function topicDetail(
    requestManager: ICommunityRequestManager,
    contentId: string
) {
    const url = "community/" + requestManager.communityId + "/topic/" + contentId + "/detail";

    return requestManager.postJson(url);
}

export function bookmark(
    requestManager: ICommunityRequestManager,
    contentId: string
): Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/topic/" + contentId + "/bookmark";

    return requestManager.postJson(url);
}

export function removeBookmark(
    requestManager: ICommunityRequestManager,
    contentId: string
): Promise<{bookmarked: boolean}> {
    const url = "community/" + requestManager.communityId + "/topic/" + contentId + "/removebookmark";

    return requestManager.postJson(url);
}

export function update(
    requestManager: ICommunityRequestManager,
    contentId: string,
    contentPatch: any,
    selects: any = null
) {
    const url = "community/" + requestManager.communityId + "/topic/" + contentId + "/update";

    return requestManager.postJson(url, JSON.stringify({
        entity: contentPatch,
        selects: selects
    }), defaultPostHeaderForJsonData);
}

export function create(
    requestManager: ICommunityRequestManager,
    contentPatch: any,
    selects: any = null
) {
    const url = "community/" + requestManager.communityId + "/topic/create";

    return requestManager.postJson(url, JSON.stringify({
        entity: contentPatch,
        selects: selects
    }), defaultPostHeaderForJsonData);
}