import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { IFrontDatasourceV3 } from '../../../api/front/datasource';
import { ICommunityProduct } from '../data';

export function productDatasourceV3(requestManager: ICommunityRequestManager) : Promise<IFrontDatasourceV3<ICommunityProduct>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/productcommunity";
        return mod.getFrontDataSource<ICommunityProduct>(
            requestManager, url
        );
    });
}

export function productsQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return productDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function productsCount(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return productDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}


